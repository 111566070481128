import DefaultNavbar from "components/DefaultNavbar";
import DefaultFooter from "components/DefaultFooter";
import Header from "components/landing/Header";
import ContactSection from "components/landing/ContactSection";

import playstore from "assets/img/google-play-badge.png";
import appstore from "assets/img/app-store-badge.png";
import andy from "assets/img/andy.jpeg";
import { FloatingWhatsApp } from "react-floating-whatsapp";

export default function Landing() {
  return (
    <>
      <div className="absolute w-full z-20">
        <DefaultNavbar />
      </div>
      <main>
        <Header />
        <p className="text-center text-xl lg:text-2xl pt-5 italic">
          Available on App store and Play store
        </p>
        <div className="flex justify-center px-10 lg:px-0 -mt-10">
          <div className="w-[150px] lg:w-[190px]">
            <a
              href="https://play.google.com/store/apps/details?id=com.lyme.ourgatekeeper&pcampaignid=web_share"
              target={"_blank"}
              rel="noreferrer"
            >
              <img src={playstore} alt="" />
            </a>
          </div>
          <div className="w-[150px] lg:w-[190px] ml-[15px]">
            <a
              href="https://apps.apple.com/us/app/our-gatekeeper/id1590818516"
              target={"_blank"}
              rel="noreferrer"
            >
              <img src={appstore} alt="" />
            </a>
          </div>
        </div>
        <ContactSection />
      </main>
      <DefaultFooter />
      <FloatingWhatsApp
        {...{
          phoneNumber: "+233263000170",
          accountName: "Our Gatekeeper",
          avatar: andy,
          notificationSound: true,
        }}
      />
    </>
  );
}
