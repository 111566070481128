import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { FuegoProvider } from "@nandorojo/swr-firestore";
import { fuego } from "config/firebase/firebase";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <FuegoProvider fuego={fuego}>
        <App />
        <Analytics />
        <SpeedInsights />
      </FuegoProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
