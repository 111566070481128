import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import CardFooter from "@material-tailwind/react/CardFooter";
import H5 from "@material-tailwind/react/Heading5";
import Input from "@material-tailwind/react/Input";
import Checkbox from "@material-tailwind/react/Checkbox";
import Button from "@material-tailwind/react/Button";
import DefaultNavbar from "components/DefaultNavbar";
import SimpleFooter from "components/SimpleFooter";
import Page from "components/login/Page";
import Container from "components/login/Container";
import { firestore } from "config/firebase/firebase";
import { useForm } from "react-hook-form";
import React from "react";

export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loginError, setLoginError] = React.useState("");
  const [processing, setProcessing] = React.useState(false);

  const findUser = async (values) => {
    try {
      setLoginError("");
      setProcessing(true);
      const snapshot = await firestore()
        .collectionGroup(`admins`)
        .where("email", "==", values?.email)
        .get();

      if (snapshot.empty) {
        return setLoginError("User does not exist");
      }

      const docs = [];
      snapshot.forEach((doc) => {
        docs.push(doc.data());
      });

      const userData = docs[0];

      if (userData) {
        const url =
          process.env.NODE_ENV === "development"
            ? `http://localhost:3000/login?email=${userData?.email}&password=${values?.password}`
            : `https://admin.ourgatekeeper.com/login?email=${userData?.email}&password=${values?.password}`;
        window.open(url, "_self");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Page>
      <DefaultNavbar />
      <Container>
        <Card>
          <CardHeader color="lightBlue">
            <H5 color="white" style={{ marginBottom: 0 }}>
              Admin Login
            </H5>
          </CardHeader>

          <CardBody>
            <div className="mb-3 px-4 bg-bb">
              <label>Email</label>
              <input
                {...register(`email`, {
                  required: "Please enter email address",
                })}
                type="email"
                color="lightBlue"
                placeholder="Email Address"
                iconName="email"
                className="w-full border border-gray-300 px-2 py-2 rounded"
              />
              <p className="text-xs text-red-500">{errors?.email?.message}</p>
            </div>
            <div className="mb-3 px-4">
              <label>Password</label>
              <input
                {...register(`password`, {
                  required: "Please enter password",
                })}
                type="password"
                color="lightBlue"
                placeholder="Password"
                iconName="lock"
                className="w-full border border-gray-300 px-2 py-2 rounded"
              />
              <p className="text-xs text-red-500">
                {errors?.password?.message}
              </p>
            </div>
            {/* <div className="mb-4 px-4">
              <Checkbox color="lightBlue" text="Remember Me" id="remember" />
            </div> */}
          </CardBody>
          <p className="text-xs text-red-500 text-center">{loginError}</p>
          <CardFooter>
            <div className="flex justify-center bg-bb">
              <Button
                disabled={processing}
                color="lightBlue"
                buttonType="link"
                size="lg"
                ripple="dark"
                onClick={handleSubmit(findUser)}
              >
                Login
              </Button>
            </div>
          </CardFooter>
        </Card>
      </Container>
      <SimpleFooter />
    </Page>
  );
}
