import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";
import "firebase/compat/database";

// import 'firebase/auth';

import axios from "axios";

export const config = {
  apiKey: process.env.REACT_APP_FIRE_BASE_KEY,
  authDomain: process.env.REACT_APP_FIRE_BASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIRE_BASE_PROJECT_ID,
  databaseURL: process.env.REACT_APP_FIRE_BASE_DB_URL,
  storageBucket: process.env.REACT_APP_FIRE_BASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIRE_BASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIRE_BASE_APP_ID,
  measurementId: process.env.REACT_APP_FIRE_BASE_MEASURMENT_ID,
};

class Fuego {
  constructor(config) {
    this.db = !firebase.apps.length
      ? firebase.initializeApp(config).firestore()
      : firebase.app().firestore();
    this.auth = firebase.auth;
    this.functions = firebase.functions;
    this.storage = firebase.storage;
    this.database = firebase.database;
  }
}

export const fuego = new Fuego(config);

export const auth = fuego.auth;
export const db = fuego.db;
export const firestore = firebase.firestore;
export const database = firebase.database;
export const storage = firebase.storage;
export const functions = firebase.functions;

export const loginWithEmail = (email, password) =>
  auth().signInWithEmailAndPassword(email, password);

export const registerWithEmail = (email, password) =>
  auth().createUserWithEmailAndPassword(email, password);

export const logout = () => {
  auth().signOut();
};

export const passwordReset = (email) => auth().sendPasswordResetEmail(email);

export const getCurrentUser = () => {
  try {
    const user = auth().currentUser;
    return user;
  } catch (error) {
    console.log(error);
  }
};

export const findUserInDB = async ({ phone }) => {
  try {
    const {
      data: { message, success },
    } = await axios.post(
      `https://us-central1-estate-security-app.cloudfunctions.net/findUserInAuth?phone=${phone}`
    );

    if (!success || !message) return null;

    const { customClaims, uid } = message;
    // console.log(customClaims, uid);
    // return;

    const snapshot = await db
      .collection(`estates`)
      .doc(customClaims?.estateID)
      .collection(customClaims?.userGroup)
      .doc(uid)
      .get();
    if (!snapshot.exists) return null;

    return snapshot.data();
  } catch (error) {
    console.log(error);
  }
};

export const userOfficer = (userGroup) => {
  return [`officers`].includes(userGroup);
};

export const userResident = (userGroup) => {
  return [`residents`].includes(userGroup);
};
